import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './Home';
import Projects from './Projects';
import Navbar from './Navbar';
import ChatBot from './ChatBot';

// Background Video Component
function BackgroundVideo() {
  return (
    <div className="fixed inset-0 z-0">
      <div className="absolute inset-0 bg-gradient-to-b from-black/95 via-[#0f0c29]/90 to-black/95 z-10" />
      <video
        autoPlay
        loop
        muted
        className="absolute top-1/2 left-1/2 min-w-full min-h-full object-cover -translate-x-1/2 -translate-y-1/2 opacity-30"
        style={{ filter: 'brightness(0.3) saturate(1.2)' }}
      >
        <source src="/assets/vid1.mp4" type="video/mp4" />
      </video>
    </div>
  );
}

// ChatPrompt Component
const ChatPrompt = ({ message, isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed bottom-24 right-4 max-w-xs bg-purple-600 text-white p-4 rounded-lg shadow-lg animate-bounce-slow z-50">
      <button
        onClick={onClose}
        className="absolute -top-2 -right-2 bg-purple-700 rounded-full p-1 hover:bg-purple-800"
      >
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <p className="text-sm">{message}</p>
    </div>
  );
};

// Main App Component
function App() {
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMessage, setPromptMessage] = useState('');
  const [hasInteracted, setHasInteracted] = useState(false);

  // Prompts configuration
  const prompts = [
    {
      message: "🚀 Want to know more about my projects? Let's chat!",
      delay: 30000,
    },
    {
      message: "💡 Got questions about my tech stack? Talk to my ai assistant!",
      delay: 60000,
    }
  ];

  // Handle user interaction
  useEffect(() => {
    const handleInteraction = () => setHasInteracted(true);
    window.addEventListener('click', handleInteraction);
    window.addEventListener('scroll', handleInteraction);
    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('scroll', handleInteraction);
    };
  }, []);

  // Manage prompts display
  useEffect(() => {
    let promptIndex = 0;
    let promptTimer;

    const showNextPrompt = () => {
      if (!hasInteracted && promptIndex < prompts.length) {
        setPromptMessage(prompts[promptIndex].message);
        setShowPrompt(true);
        promptIndex++;

        // Hide prompt after 5 seconds
        setTimeout(() => setShowPrompt(false), 5000);

        // Schedule next prompt
        if (promptIndex < prompts.length) {
          promptTimer = setTimeout(showNextPrompt, prompts[promptIndex].delay);
        }
      }
    };

    // Start first prompt
    promptTimer = setTimeout(showNextPrompt, prompts[0].delay);

    return () => {
      clearTimeout(promptTimer);
    };
  }, [hasInteracted]);

  const PageContainer = () => {
    const location = useLocation();

    // Reset hasInteracted when route changes
    useEffect(() => {
      setHasInteracted(false);
    }, [location]);

    return (
      <div className="pt-16 relative z-20">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    );
  };

  return (
    <Router>
      <div className="min-h-screen bg-black text-white relative">
        <BackgroundVideo />
        <div className="relative z-30">
          <Navbar />
          <PageContainer />
          <ChatBot />
          <ChatPrompt
            message={promptMessage}
            isVisible={showPrompt}
            onClose={() => {
              setShowPrompt(false);
              setHasInteracted(true);
            }}
          />
        </div>
      </div>
    </Router>
  );
}

export default App;
